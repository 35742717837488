import React from "react";

import CreateForm from "../../components/CreateForm";


const CreateTodo = () => {

  return (
    <div>
      <h1>CreateTodo</h1>
      <CreateForm />
    </div>
  )
};

export default CreateTodo;