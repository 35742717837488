import React from "react";


const NotFoundPage = () => {

  return (
    <div>
      <h1>404 Not Found</h1>
      <h3>Данная страница не существует.</h3>
    </div>
  )
};

export default NotFoundPage;