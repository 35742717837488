import axios from 'axios'

const baseURL = 'http://95.163.228.165/';

const api = axios.create({
  baseURL
})

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
